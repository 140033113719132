/* button {
  background-color: #333;
  color: #fff;
  font-size: 1.2rem;
  padding: 1rem;
  width: 100%;
  text-align: left;
  border: none;
  border-radius: 0.2rem;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #ddd;
} */


button:last-child {
  border-bottom: none;
}

button:hover {
  cursor: pointer;
}

.Buttons {
  margin-left: 2rem;
}
