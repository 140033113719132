.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  input[type="text"],
  input[type="email"],
  select,
  input[type="checkbox"] {
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    border: none;
  }
  
  input[type="submit"] {
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  input[type="submit"]:hover {
    background-color: #3e8e41;
  }
  
  .error-message {
    color: red;
    margin: 5px 0;
  }
  
  .success-message {
    color: green;
    margin: 5px 0;
  }
  