/* Banner.css */
.banner-container {
    background-color: #f0f0f0;
    padding: 10px;
    text-align: center;
  }
  
  .banner-message {
    font-size: 18px;
    color: #333;
  }
  