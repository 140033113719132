body
{
  margin-left: 30px;
  margin-right: 30px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.form-group label {
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.form-control {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.is-invalid {
  border-color: red;
  margin-top: 0.25rem;
  font-size: 0.9rem;
}

.btn-secondary {
  margin-left: 1rem;
}

.error {
  color: red;
  margin-top: 0.5rem;
  font-size: 0.9rem;
}
.error-message {
  color: red;
  margin-top: 0.5rem;
  font-size: 0.9rem;
}

.button {
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 0.2rem;
  padding: 0.5rem 1rem;
  text-decoration: none;
  cursor: pointer;
}

