h2 {
  margin: 10px 0;
  font-size: 24px;
}

.timer,
.clock,
.message {
  font-size: 36px;
  margin: 10px 0;
}

.timer,
.clock {
  color: #ff4500;
}

.message {
  color: #32cd32;
  font-weight: bold;
}
