.navbar {
  background-color: #f5f5f5;
  padding: 10px;
  padding-bottom: 30px;
}

.nav-toggle {
  display: none;
  cursor: pointer;
}

.nav-links {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-links.show {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-link {
  text-decoration: none;
  font-weight: 600;
  margin: 0 15px;
  padding: 10px 0;
  border-bottom: 2px solid transparent;
  color: #333;
  transition: all 0.3s ease;
}

.nav-link:hover,
.nav-link.active {
  color: #ffdf6b;
}

.nav-link.active {
  color: #ffa500;
}

.nav-link:not(.active):not(.nav-link:last-child):hover {
  color: #ffdf6b;
}

.logout-link {
  color: #333;
  text-decoration: none;
  font-weight: 600;
  margin: 0 15px;
  padding: 10px 0;
}

.logout-link:hover {
  color: #ffdf6b;
  border-color: #ffdf6b;
}

.logout-link.selected {
  color: #333;
  border-bottom: 2px solid transparent;
}

.account-links{
  margin-left: 10%;
}

@media screen and (max-width: 768px) {
  .navbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative; /* Add this line */
    z-index: 999; /* Add this line */


  }

  .nav-toggle {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    width: 30px;
    height: 25px;
  }

  .nav-toggle span {
    display: block;
    height: 2px;
    background-color: #333;
    margin-bottom: 5px;
    position: relative;
    width: 100%;
    transition: all 0.3s ease;
  }

  .nav-links {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #f5f5f5;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .nav-links.show {
    display: flex;
  }

  .nav-link,
  .logout-link {
    margin: 10px 0;
    padding: 5px 0;
    border-bottom: none;
  }

  .nav-link.active {
    color: #ffa500;
    border-bottom: none;
  }
  .close-btn{
    position: absolute;
    padding: 10px;
    top: 0px;
    left: 0px;
  }
}
