.links-container {
  display: flexbox;
  align-items: center;
  margin-top: 30px ;
}

.links-container a {
  margin-left: 10px;
  background: white;
  border-radius: 0.2rem;
  padding: 0.5rem 1rem;
}



