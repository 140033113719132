button {
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 0.2rem;
  padding: 0.5rem 1rem;
  margin-right: 2%;
  text-decoration: none;
  cursor: pointer;
}

button:hover {
  background-color: #fff;
  color: #333;
}

label {
  margin-left: 5px;
  font-weight: bold;
}

input[type="radio"] {
  display: inline;
  margin: 0 5px 0 0;
}

div {
  margin: 10px;
}

iframe {
  width: 100%;
  height: 500px;
  border: none;
}

p {
  margin: 5px;
  font-weight: bold;
}

input[type="checkbox"] {
  margin-right: 5px;
}

a {
  color: blue;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

select {
  margin: 10px;
  padding: 5px;
}

input[type="text"] {
  padding: 5px;
  margin: 10px;
}
.radio-container {
  display: flex;
  align-items: center;
  margin: 10px;
}
div {
  margin-bottom: 30px;
}
h2{
  margin-top: 20px;
  margin-bottom: 20px;

}

.container-outline{
  padding: 0;
  background-color: #404148;
  border-style: solid;
  border-width: 5px;
  border-color: #8290e0;
}

.container-text{
  color: white;
  text-align: center;
}

.practice-button {
  background-color: #333;
  color: #fff;
  font-size: 1.2rem;
  padding: 1rem;
  width: 100%;
  text-align: left;
  border: none;
  border-radius: 0.2rem;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #ddd;
}


