.drag-and-drop {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .small-box {
    width: 100px;
    height: 100px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
    line-height: 100px;
    cursor: move;
    transition: all 0.2s ease-in-out;
  }
  
  .small-box.dragging {
    border: 1px solid #000;
    background-color: #eee;
  }
  
  .large-box {
    width: 200px;
    height: 200px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
    line-height: 200px;
    transition: all 0.2s ease-in-out;
  }
  
  .large-box.inside {
    background-color: #aaffaa;
  }
  