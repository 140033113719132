.component-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  
  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .radio-button-container, .radio-container {
    display: flex;
    align-items: center;
    width: 50%;

    margin: 10px;
    background-color: #fcfbfb;
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
  }
  
  input[type="radio"] {
    margin-right: 10px;
  }
  
  label {
    font-size: 16px;
  }
  
  p {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
  }
  