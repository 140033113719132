/* .container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    background-color: #fff;
  } */
  
  h1 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #333;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  input[type='text'],
  input[type='password'] {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: large;
  }
  
  button[type='submit'] {
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  button[type='submit']:hover {
    background-color: #fff;
    color: #333;
  }
  
  .error {
    color: red;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }

  