.container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  
  .col-md-6 {
    width: 50%;
    padding: 0 15px;
    margin-bottom: 30px;
  }
  
  h1 {
    font-size: 48px;
    font-weight: 700;
    margin-top: 50px;
    margin-bottom: 30px;
    text-align: center;
  }
  
  h2 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
    position: relative;
  }
  
  h2 span {
    font-size: 28px;
    font-weight: 700;
    position: absolute;
    top: 0;
    right: -25px;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  h2 span:hover {
    transform: scale(1.1);
  }
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  li {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  
  label {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
    display: block;
  }
  
  input[type="text"] {
    font-size: 18px;
    font-weight: 400;
    padding: 10px;
    border-radius: 5px;
    border: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-bottom: 20px;
  }
  
  button[type="submit"] {
    font-size: 18px;
    font-weight: 700;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    background-color: #333;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  button[type="submit"]:hover {
    background-color: #fff;
    color: #333;
  }
  
  button[type="submit"]:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
  }
  
  