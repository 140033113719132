.checkbox-container {
    display: flexbox;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .checkbox-container label {
    margin-left: 10px;
    margin-top: -35px;
    background: white;
    border-radius: 0.2rem;
    padding: 0.5rem 1rem;
  }
  
  
  .checkbox-container input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .checkbox-container p {
    margin-left: 10px;
    color: green;
    font-style: italic;
  }
  