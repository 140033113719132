.file-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  label {
    margin-top: 20px;
    font-size: 16px;
  }
  
  input[type="file"] {
    margin-top: 10px;
    font-size: 16px;
  }
  
  button {
    margin-top: 10px;
    font-size: 16px;
    padding: 8px 16px;
    background-color: #0077cc;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .error-message {
    margin-top: 20px;
    font-size: 16px;
    color: red;
  }
  