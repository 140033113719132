/* ProductList.css */

body{
  background-color: #f7f7f7; 
}

.product-list-container {
  width: 90%;
  margin: 0 auto;
}

.product-list-container h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.product-list-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 2rem;
}

.product-list-table th {
  background-color: #333;
  color: #fff;
  padding: 1rem;
  text-align: left;
}

.product-list-table td {
  border: 1px solid #ccc;
  padding: 1rem;
}

.product-list-table td:first-child {
  font-weight: bold;
}

.product-list-actions {
  display:flex;
  align-items: center;
  justify-content: space-between;
  width: 10rem;
}

.product-list-actions a,
.product-list-actions button,
.show-more-button .button {
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 0.2rem;
  padding: 0.5rem 1rem;
  text-decoration: none;
  cursor: pointer;
}

.product-list-actions a:hover,
.product-list-actions button:hover,
.show-more-button:hover {
  background-color: #fff;
  color: #333;
}

.add-product-link {
  display: block;
  text-align: center;
  margin-top: 2rem;
}

.add-product-link a {
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 0.2rem;
  padding: 0.5rem 1rem;
  text-decoration: none;
  cursor: pointer;
}

.add-product-link a:hover {
  background-color: #fff;
  color: #333;
}

.show-more-link button {
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 0.2rem;
  padding: 0.5rem 1rem;
  text-decoration: none;
  cursor: pointer;
}

.show-more-link button:hover {
  background-color: #fff;
  color: #333;
}

.filter-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.filter-textbox {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.2rem;
  margin-right: 1rem;
  width: 60%;
}

.filter-button, .reset-button {
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 0.2rem;
  padding: 0.5rem 1rem;
  text-decoration: none;
  cursor: pointer;
  margin-left: 1%;
}

.filter-button:hover, .reset-button:hover {
  background-color: #fff;
  color: #333;
}
